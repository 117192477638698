/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'

/**
 * Web standards
 */
import './menu'
import './lazyload'
import './cookieconsent'
import './transitions'
import './monitor'

/**
 * Scripts which doesn't need dynamic import
 */
import {  Modal } from 'bootstrap';

/**
 * Dynamic import scripts
 */
const elements = [
  '.parallax',
  '.prikr-fadein',
  '.testimonials',
  '.blog'

];

[].forEach.call(elements, (element) => {

  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      // Parallax
      if (element === '.parallax') {
        const Filters = import('./parallax')
      }

      // scrollTriggers
      if (element === '.prikr-fadein') {
        const Filters = import('./scrollTriggers')
      }

      // Testimonials
      if (element === '.testimonials') {
        const Filters = import('./testimonials')
      }

      // Blogs
      if (element === '.blog') {
        const Filters = import('./loadmore')
      }

    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }

  }
});
