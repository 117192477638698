const hamburgerWrapper = document.querySelector(".hamburger__wrapper");
const hamburger = document.querySelector(".hamburger");
const body = document.querySelector('body');
const navigation = document.querySelector('.navigation');

hamburgerWrapper.addEventListener("click", function() {
  hamburger.classList.toggle("is-active");
  body.classList.toggle('modal-open');
  body.classList.toggle('navigation-is-open');
  navigation.classList.toggle('navigation__is-open');
});

