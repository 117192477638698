import scrollMonitor from 'scrollmonitor'
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase);

class Segment {
  constructor (s, cp, e) {
    this.s = s
    this.cp = cp
    this.e = e
  }
}

(function () {
  CustomEase.create('smoothInOut', '0.275, 0.420, 0.000, 1.000')
  CustomEase.create('slide', '.8,.14,.24,.99')
  const duration = 0.3

  const els = document.getElementsByClassName('watch-element')

  if (els) {
    for (let i = 0; i < els.length; i++) {
      const el = els[i]
      const maskEls = el.querySelectorAll('.mask-in')

      for (let j = 0; j < maskEls.length; j++) {
        const maskEl = maskEls[j]

        const maskElements = {}

        // Base timeline
        const timeline = gsap.timeline()

        // If there's a mask
        if (maskEl) {
          // Add to maskElements object
          maskElements.mask = maskEl.querySelector('.mask-in__mask')
          maskElements.fade = maskEl.querySelector('.mask-in__fade')

          // Set standards
          if (maskElements.mask) { 
            gsap.set(maskElements.fade, {
              opacity: 0,
              scale: 1.075,
              force3D: !0
            })
          }

          if (maskElements.mask) { 
            gsap.set(maskElements.mask, {
              y: '100%',
              force3D: !0
            })
          }
        }

        // Create watcher
        const elWatcher = scrollMonitor.create(el, 0)
        const showElement = () => {
          el.classList.add('is-in-viewport')

          // If there's a mask
          if (maskEl) {
            if (maskElements.mask) {
              // Slide the mask in
              timeline.to(maskElements.mask, duration, {
                y: '0%',
                ease: 'slide',
                onComplete () {
                  if (maskElements.fade) {
                    // Remove opacity from fade element
                    gsap.set(maskElements.fade, {
                      clearProps: 'opacity'
                    })
                  }

                  // Slide the mask out
                  gsap.to(maskElements.mask, duration, {
                    y: '-101%',
                    ease: 'slide'
                  })
                }
              })
            } else {

              // console.warn('Monitor.js - Add a mask')

            }

            if (maskElements.fade) {
              // Transform the fade element
              timeline.to(maskElements.fade, 1.1, {
                scale: 1,
                ease: 'smoothInOut',
                clearProps: 'scale',
                onComplete () {
                  el.classList.add('is-completed')
                  // And reset back to normal
                  gsap.set(maskElements.fade, {
                    clearProps: 'transform'
                  })
                }
              }, duration / 2)
            } else {

              // console.warn('Monitor.js - Add a fade')
              
            }
          }

          if (maskElements.mask) {
            timeline.eventCallback('onComplete', () => {
              maskElements.mask.classList.add('hidden')
              maskElements.mask.parentNode.removeChild(maskElements.mask)
            })
          }

          // Destroy the watcher
          elWatcher.destroy()
        }

        // Do when element enters viewport
        elWatcher.exitViewport(showElement)
        elWatcher.enterViewport(showElement)
      }
    }
  }
})()
