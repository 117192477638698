import { onVisibilityChange, isElementInViewport } from './helpers';
import gsap from 'gsap'; 
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
gsap.registerPlugin(CSSRulePlugin, DrawSVGPlugin);

/**
 * Page transitions
 */
window.onload = () => {
  const transitions = document.querySelectorAll('.transition');
  
  transitions.forEach((transition) => {
    setTimeout(() => {
      

      transition.classList.remove('transition__is-active');
      transition.classList.add('was-active');
    }, 900);
  })
}

const anchors = document.querySelectorAll('a');
anchors.forEach((anchor) => {
  const transitions = document.querySelectorAll('.transition');

  anchor.addEventListener('click', (e) => {
    e.preventDefault();

    let target = e.target;

    if (e.target.nodeName !== "A") {
      target = e.target.parentNode.href;
    } else {
      target = e.target.href;
    }

    transitions.forEach((transition) => {
      if (transition.classList.contains('transition__slide-down')) {
        transition.classList.remove( 'transition__slide-down' );
        transition.classList.add( 'transition__slide-up' );
      }
      setTimeout(() => {
        transition.classList.add('transition__is-active-leaving');
      }, 100)
    })

    setTimeout(() => {
      window.location.href = target;
    }, 600);
  })
})

const btnSecondaryArrow = document.querySelectorAll('.btn-with-arrow');
btnSecondaryArrow.forEach((btn) => {
  btn.addEventListener('mouseover', () => {
    let rule = CSSRulePlugin.getRule(".btn-with-arrow:before");
    gsap.to(rule, {
      duration: 0.7,
      ease: "back.out(1)",
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'37.003\' height=\'12.11\' viewBox=\'0 0 37.003 12.11\'%3E%3Cg id=\'Group_85\' data-name=\'Group 85\' transform=\'translate(-102 -5088.585)\'%3E%3Cg id=\'Group_79\' data-name=\'Group 79\' transform=\'translate(132.948 5090)\'%3E%3Cpath id=\'Path_81\' data-name=\'Path 81\' d=\'M18013.947-526.473l4.641,4.641-4.641,4.641\' transform=\'translate(-18013.947 526.473)\' fill=\'none\' stroke=\'%23000\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3Cline id=\'Line_12\' data-name=\'Line 12\' x1=\'33.448\' transform=\'translate(103 5094.5)\' fill=\'none\' stroke=\'%23000\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3C/svg%3E%0A")'
    });
  })

  btn.addEventListener('mouseleave', () => {
    let rule = CSSRulePlugin.getRule(".btn-with-arrow:before");

    gsap.to(rule, {
      duration: 0.7,
      ease: "back.out(1)",
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'23.555\' height=\'12.11\' viewBox=\'0 0 23.555 12.11\'%3E%3Cg id=\'Group_85\' data-name=\'Group 85\' transform=\'translate(-102 -5088.586)\'%3E%3Cg id=\'Group_79\' data-name=\'Group 79\' transform=\'translate(-601.729 4437.5)\'%3E%3Cpath id=\'Path_81\' data-name=\'Path 81\' d=\'M18013.947-526.473l4.641,4.641-4.641,4.641\' transform=\'translate(-17292.719 1178.973)\' fill=\'none\' stroke=\'%23000\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3Cline id=\'Line_12\' data-name=\'Line 12\' x1=\'20\' transform=\'translate(103 5094.5)\' fill=\'none\' stroke=\'%23000\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3C/svg%3E%0A")'
    });
  })
});

const btnSecondaryArrowWhite = document.querySelectorAll('.btn-with-arrow-white');
btnSecondaryArrowWhite.forEach((btn) => {

  let rule = CSSRulePlugin.getRule(".btn-with-arrow-white:before");
  btn.addEventListener('mouseover', () => {
    gsap.to(rule, {
      duration: 0.7,
      ease: "back.out(1)",
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'37.003\' height=\'12.11\' viewBox=\'0 0 37.003 12.11\'%3E%3Cg id=\'Group_85\' data-name=\'Group 85\' transform=\'translate(-102 -5088.585)\'%3E%3Cg id=\'Group_79\' data-name=\'Group 79\' transform=\'translate(132.948 5090)\'%3E%3Cpath id=\'Path_81\' data-name=\'Path 81\' d=\'M18013.947-526.473l4.641,4.641-4.641,4.641\' transform=\'translate(-18013.947 526.473)\' fill=\'none\' stroke=\'%23fff\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3Cline id=\'Line_12\' data-name=\'Line 12\' x1=\'33.448\' transform=\'translate(103 5094.5)\' fill=\'none\' stroke=\'%23fff\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3C/svg%3E%0A")'
    });
  })

  btn.addEventListener('mouseleave', () => {
    gsap.to(rule, {
      duration: 0.7,
      ease: "back.out(1)",
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'23.555\' height=\'12.11\' viewBox=\'0 0 23.555 12.11\'%3E%3Cg id=\'Group_85\' data-name=\'Group 85\' transform=\'translate(-102 -5088.586)\'%3E%3Cg id=\'Group_79\' data-name=\'Group 79\' transform=\'translate(-601.729 4437.5)\'%3E%3Cpath id=\'Path_81\' data-name=\'Path 81\' d=\'M18013.947-526.473l4.641,4.641-4.641,4.641\' transform=\'translate(-17292.719 1178.973)\' fill=\'none\' stroke=\'%23fff\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3Cline id=\'Line_12\' data-name=\'Line 12\' x1=\'20\' transform=\'translate(103 5094.5)\' fill=\'none\' stroke=\'%23fff\' stroke-linecap=\'round\' stroke-width=\'2\'/%3E%3C/g%3E%3C/svg%3E%0A")'
    });
  })
});




const processIcons = document.querySelectorAll('.process-icon'); 
processIcons.forEach( (icon, i) => {

  let GSAP_EL = '#icon-' + (i + 1);
  const animate = () => {
    if (isElementInViewport(icon)) {
      if (window[`icon-${i}-loaded`] === false || window[`icon-${i}-loaded`] === undefined) {
        window[`icon-${i}-loaded`] = true;
        gsap.set([GSAP_EL], {
          visibility: "visible"
        });
        
        gsap.to(GSAP_EL, {
          drawSVG: true,
          duration: 2,
          ease: "power3.inOut",
        });
      }   
    }
  };

  if (window.addEventListener) {
    icon.addEventListener('mouseover', animate, false);
    addEventListener('scroll', animate, false);
    addEventListener('load', animate, false);
  } else if (window.attachEvent) {
    icon.attachEvent('mouseover', animate);
    attachEvent('onscroll', animate);
    attachEvent('onload', animate);
  }

});
